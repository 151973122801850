import { isBuyNowAd, isPayliveryAd } from '@bbx/common/lib/isPayliveryAd'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { Price } from '@bbx/common/types/Price'
import type {
    sendRequestMail as defaultSendRequestMail,
    sendRequestMessage as defaultSendRequestMessage,
} from '@bbx/lead-journey/lead-journey/api/adContactApiClient'
import { AdContactFormMessaging } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/AdContactFormMessaging'
import { PayliveryPriceBox } from '@bbx/lead-journey/lead-journey/components/ContactBox/PayliveryPriceBox'
import { Box } from '@wh-components/core/Box/Box'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { Text } from '@wh-components/core/Text/Text'
import { UserProfileData } from '@wh/common/chapter/types/user'
import type {
    needsToAcceptSmsAllowance as defaultNeedsToAcceptSmsAllowance,
    setSmsAllowanceStatusAccepted as defaultSetSmsAllowanceStatusAccepted,
} from '@wh/common/sms-allowance/api/smsAllowanceApiClient'
import React, { Fragment, FunctionComponent, RefObject } from 'react'
import { BuyNowButton } from '../../BuyNowButton/BuyNowButton'
import { OfferButton } from '../../OfferButton'
import { BapAdContactFormPayLiveryBanner } from './BapAdContactFormPayLiveryBanner'

export interface BapAdContactFormProps {
    advertDetails: AdvertDetails
    mainPrice: (advertDetails: AdvertDetails) => Price
    profileData: UserProfileData | undefined
    onAdvertisingStateContacted: () => void
    adContactVisibilityTrackingRef: RefObject<HTMLDivElement>

    // mockable props that are provided with defaults
    sendRequestMail?: typeof defaultSendRequestMail
    sendRequestMessage?: typeof defaultSendRequestMessage
    needsToAcceptSmsAllowance?: typeof defaultNeedsToAcceptSmsAllowance
    setSmsAllowanceStatusAccepted?: typeof defaultSetSmsAllowanceStatusAccepted
}

export const BapAdDetailContactFormMessaging: FunctionComponent<BapAdContactFormProps> = (props) => {
    const isPaylivery = isPayliveryAd(props.advertDetails)
    const isBuyNow = isBuyNowAd(props.advertDetails)

    return (
        <Fragment>
            {isPaylivery ? (
                <Box>
                    <Box>
                        <Box>
                            <PayliveryPriceBox
                                mainPrice={props.mainPrice(props.advertDetails)}
                                deliveryOptions={props.advertDetails.p2ppOptions?.deliveryOptions}
                                advertStatus={props.advertDetails.advertStatus}
                                withDetailedShippingAddons={true}
                                testIdPrefix="contact-form"
                            />
                        </Box>
                        <Box
                            display="flex"
                            marginTop={{ tablet: 's', desktop: 'm' }}
                            marginBottom="m"
                            gap="s"
                            flexDirection={{ phone: 'column-reverse', desktop: 'row' }}
                        >
                            <Box display="flex" width="100%" gap="s" flexDirection={{ phone: 'column', desktop: 'row' }}>
                                {isBuyNow && (
                                    <BuyNowButton
                                        width={{ phone: '100%', desktop: '50%' }}
                                        minWidth={{ desktop: '200px' }}
                                        adId={props.advertDetails.id}
                                        testId="bap-contact-form-buynow-button"
                                        eventName="addetail_contactform_buynow_click"
                                        taggingData={props.advertDetails.taggingData}
                                        onClick={props.onAdvertisingStateContacted}
                                    />
                                )}
                                <OfferButton
                                    adId={props.advertDetails.id}
                                    minWidth={{ desktop: '200px' }}
                                    width={{ phone: '100%', desktop: isBuyNow ? '50%' : '100%' }}
                                    eventName="addetail_contactform_offer_click"
                                    taggingData={props.advertDetails.taggingData}
                                    testId="bap-contact-form-offer-button"
                                    variant={isBuyNow ? 'outline' : 'solid'}
                                    onClick={props.onAdvertisingStateContacted}
                                />
                            </Box>

                            <Box
                                display="flex"
                                width="100%"
                                paddingVertical="s"
                                marginLeft={{ desktop: 'm' }}
                                flexDirection={{ phone: 'row-reverse', desktop: 'column' }}
                                alignItems={{ phone: 'center', desktop: 'baseline' }}
                                justifyContent={{ phone: 'space-between', desktop: 'flex-end' }}
                            >
                                <Box display="flex" gap="xs" alignItems="flex-start">
                                    {props.advertDetails.p2ppOptions?.paymentOptions.map(({ iconUrl, description }, index) => (
                                        <ResponsiveImage
                                            key={index}
                                            src={iconUrl}
                                            alt={description}
                                            title={description}
                                            width={32}
                                            cssHeight="auto"
                                        />
                                    ))}
                                </Box>
                                <Text fontSize="s" color="palette.raccoon">
                                    Onlinezahlung möglich
                                </Text>
                            </Box>
                        </Box>
                    </Box>

                    <BapAdContactFormPayLiveryBanner />

                    <Box paddingVertical="m">
                        <Text display={{ phone: 'block', tablet: 'inline' }}>Du hast vorab Fragen zum Artikel?</Text>
                        <Text> Sende eine Nachricht!</Text>
                    </Box>
                </Box>
            ) : (
                <Box paddingBottom="m">
                    <Text as="p">Sende eine Nachricht!</Text>
                </Box>
            )}
            <AdContactFormMessaging
                advertDetails={props.advertDetails}
                profileData={props.profileData}
                onAdvertisingStateContacted={props.onAdvertisingStateContacted}
                adContactVisibilityTrackingRef={props.adContactVisibilityTrackingRef}
                sendRequestMessage={props.sendRequestMessage}
                needsToAcceptSmsAllowance={props.needsToAcceptSmsAllowance}
                setSmsAllowanceStatusAccepted={props.setSmsAllowanceStatusAccepted}
                messagingInfoTitle="Finde noch andere passende Objekte!"
                messagingInfoBody="Geh zurück zum Suchergebnis und entdecke weitere super Angebote."
                buttonVariant={isPayliveryAd(props.advertDetails) ? 'outline' : undefined}
            />
        </Fragment>
    )
}
