import React, { FunctionComponent } from 'react'
import { Card } from '@wh-components/core/Card/Card'
import { OrderNowButton } from '@bbx/lead-journey/lead-journey/components/OrderNowButton'
import { Text } from '@wh-components/core/Text/Text'
import { ExternalContactTypeWithDetails } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/ContactType'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { Box } from '@wh-components/core/Box/Box'

interface OrderNowContactFormProps {
    contactTypeWithDetails: ExternalContactTypeWithDetails
    advertDetails: AdvertDetails
    priceBox: (advertDetails: AdvertDetails) => React.ReactNode
    onAdvertisingStateContacted: () => void
}

export const OrderNowContactForm: FunctionComponent<OrderNowContactFormProps> = ({
    advertDetails,
    contactTypeWithDetails,
    priceBox,
    onAdvertisingStateContacted,
}) => {
    return (
        <Card>
            <Box display="flex" gap="m" flexDirection="column">
                {priceBox?.(advertDetails)}
                <OrderNowButton
                    url={contactTypeWithDetails.link}
                    taggingData={advertDetails.taggingData}
                    width={{ phone: '100%', tablet: '50%' }}
                    testId="contact-form-order-now-button"
                    onClick={onAdvertisingStateContacted}
                >
                    {contactTypeWithDetails.buttonText}
                </OrderNowButton>
                <Text fontSize="s">Die Kontaktaufnahme ist nur direkt über die Website des Shops möglich.</Text>
            </Box>
        </Card>
    )
}
