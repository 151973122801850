import React, { FunctionComponent, RefObject } from 'react'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { AdDetailStickyFooter } from '@bbx/search-journey/sub-domains/ad-detail/components/common/StickyFooter/AdDetailStickyFooter'
import { OfferButton } from '@bbx/lead-journey/lead-journey/components/OfferButton'
import { BuyNowButton } from '@bbx/lead-journey/lead-journey/components/BuyNowButton/BuyNowButton'
import { Box } from '@wh-components/core/Box/Box'
import { isBuyNowAd } from '@bbx/common/lib/isPayliveryAd'

interface BapMakeOfferAndBuyNowAdDetailStickyFooterProps {
    stickyFooterVisibilityTrackingRef: RefObject<HTMLDivElement>
    advertDetails: AdvertDetails
    onAdvertisingStateContacted: () => void
}

export const BapMakeOfferAndBuyNowAdDetailStickyFooter: FunctionComponent<BapMakeOfferAndBuyNowAdDetailStickyFooterProps> = ({
    stickyFooterVisibilityTrackingRef,
    advertDetails,
    onAdvertisingStateContacted,
}) => {
    const { id, taggingData } = advertDetails
    const isBuyNow = isBuyNowAd(advertDetails)

    return (
        <AdDetailStickyFooter
            stickyFooterVisibilityTrackingRef={stickyFooterVisibilityTrackingRef}
            buttons={
                <Box display="grid" gridTemplateColumns={isBuyNow ? '1fr 1fr' : '1fr'} gap="s" width="100%">
                    {isBuyNow && (
                        <BuyNowButton
                            eventName="addetail_sticky_buynow_click"
                            taggingData={advertDetails.taggingData}
                            adId={id}
                            testId="bap-sticky-footer-buynow-button"
                            onClick={onAdvertisingStateContacted}
                        />
                    )}
                    <OfferButton
                        adId={id}
                        eventName="addetail_sticky_offer_click"
                        taggingData={taggingData}
                        optimizelyTrackEvent="bbx_bottom_sticky_button_offer_small"
                        variant={isBuyNow ? 'outline' : 'solid'}
                        testId="bap-sticky-footer-offer-button"
                        onClick={onAdvertisingStateContacted}
                    />
                </Box>
            }
        />
    )
}
