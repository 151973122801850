import { findContextLinkWithId, SimilarAdsContextLink } from '@bbx/common/types/contextLinks'
import { BapAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/BapAdvertSummaryComponent'
import {
    trackSimilarAdsClick,
    trackSimilarAdsShowMore,
    useSimilarAds,
} from '@bbx/search-journey/sub-domains/ad-detail/components/common/SimilarAds/useSimilarAds'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { Fragment, FunctionComponent, useContext, useRef } from 'react'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { getBapSimilarAdsHref } from '@bbx/search-journey/common/lib/getBapSimilarAdsHref'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { scrollToElement } from '@wh/common/chapter/lib/commonHelpers'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import { FIXED_HEADER_HEIGHT_PHONE } from '@wh/common/chapter/lib/config/constants'
import { AdvertSummarySkeletons } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummarySkeleton'
import { Heading } from '@wh-components/core/Heading/Heading'
import { css } from 'styled-components'
import { Divider } from '@wh-components/core/Divider/Divider'
import { Box } from '@wh-components/core/Box/Box'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { theme } from '@wh-components/core/theme'
import { Button } from '@wh-components/core/Button/Button'
import { SkeletonLine } from '@wh/common/chapter/components/Skeletons/Skeletons'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { Text } from '@wh-components/core/Text/Text'
import { hiddenScrollbar } from '@wh/common/chapter/components/hiddenScrollbar'
import { adFocusHoverStyles } from '@wh/common/chapter/components/adFocusHoverStyles'

export interface BapSimilarAdsProps {
    similarAdsRecommendationContextLink: SimilarAdsContextLink
    taggingData: TaggingData
    onClickShowMoreLink?: () => void
    onClickShowMoreButton?: () => void
    onClickAd?: (adId: string, index: number) => void
}

export const BapSimilarAds: FunctionComponent<BapSimilarAdsProps> = ({
    similarAdsRecommendationContextLink,
    taggingData,
    onClickShowMoreLink,
    onClickShowMoreButton,
    onClickAd,
}) => {
    const verticalId = verticalIdMap.BAP

    const { searchResult, isLoading, visibilityTrackingRef } = useSimilarAds(similarAdsRecommendationContextLink, taggingData, verticalId)
    const getMoreAdsLink =
        similarAdsRecommendationContextLink.type === 'similar-ads'
            ? getBapSimilarAdsHref(similarAdsRecommendationContextLink)
            : searchResult && buildSeoUrl(findContextLinkWithId('similarAdsSearchLink', searchResult.searchContextLinks)?.relativePath)

    const headerHeight = useResponsiveValue({ phone: FIXED_HEADER_HEIGHT_PHONE, tablet: 100 }, 0)
    const numberOfAdsInSlider = 4

    const advertSummaries = searchResult?.advertSummaryList.advertSummary.slice(0, numberOfAdsInSlider).map((advertSummary, index) => (
        <BapAdvertSummaryComponent
            key={advertSummary.id}
            advertSummary={advertSummary}
            variant="portrait"
            onClickTaggingEvent={() => {
                trackSimilarAdsClick(similarAdsRecommendationContextLink, taggingData, verticalId, advertSummary.id, index)
                onClickAd?.(advertSummary.id, index)
            }}
        />
    ))

    const numberOfAdsToDisplay = 5
    const advertisingState = useContext(AdvertisingStateContext)
    const hoverStyle = advertisingState.pageModifications.backgroundColors?.['startpage-ad-hover']
    const feedColor = advertisingState.pageModifications.backgroundColors?.['startpage-feed']
    const accentColor = advertisingState.pageModifications.foregroundColors?.['startpage-accent-color']
    const buttonBackgroundColor = feedColor ?? theme.colors.palette.babyseal

    /* to have a consistent width when there are less then e.g. 5 children
     *  100% / 5 -> but we need to exclude the margins between the children
     *  calc(100% - margins) / 5 */
    const itemWidthDesktop = `calc((100% - ${theme.space.s * 2 * (numberOfAdsToDisplay - 1)}px) / ${numberOfAdsToDisplay})`
    const itemWidth = { phone: '37.5%', tablet: '26.5%', desktop: itemWidthDesktop }

    const scrollContainerRef = useRef<HTMLDivElement>(null)

    const onClick = () => {
        trackSimilarAdsShowMore('ad_widget_show_all_click', similarAdsRecommendationContextLink, taggingData, verticalId)
        onClickShowMoreLink?.()
    }
    const onClickButton = () => {
        trackSimilarAdsShowMore('ad_widget_show_all_button_click', similarAdsRecommendationContextLink, taggingData, verticalId)
        scrollToElement(document.getElementById('bap-similar-ads-endless'), 'start', 'always', headerHeight)
        onClickShowMoreButton?.()
    }

    return (
        <Box testId="ad-detail-similar-ads-bap" ref={visibilityTrackingRef} paddingLeft={{ phone: 'm', tablet: 0 }}>
            {isLoading ? (
                <AdvertSummarySkeletons marginBottom="l" variant="portrait" />
            ) : (
                <Box testId="ad-detail-similar-ads-bap-wrapper" ref={visibilityTrackingRef}>
                    <Box display="flex" marginBottom="s" alignItems="flex-end">
                        {isLoading ? (
                            <Fragment>
                                <Box height={20} width={{ phone: '100px', tablet: '150px' }}>
                                    <SkeletonLine />
                                </Box>
                                <Box height={20} width="100px" marginLeft="auto" marginRight={{ phone: 'm', tablet: '0' }}>
                                    <SkeletonLine />
                                </Box>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Heading
                                    level={2}
                                    text={similarAdsRecommendationContextLink.displayText}
                                    fontSize={{ phone: 'l', tablet: 'xl' }}
                                />

                                <Box marginLeft="auto" paddingLeft="sm" marginRight={{ phone: 'm', desktop: '0' }}>
                                    <ServerRoutingAnchorLink
                                        marginLeft="auto"
                                        type="anchor"
                                        href={getMoreAdsLink}
                                        testId="ad-detail-similar-ads-bap-slider-link`"
                                        onClick={onClick}
                                        color={accentColor}
                                    >
                                        <Text
                                            fontSize="s"
                                            fontWeight="bold"
                                            css={css`
                                                white-space: nowrap;
                                            `}
                                        >
                                            Mehr anzeigen
                                        </Text>
                                    </ServerRoutingAnchorLink>
                                </Box>
                            </Fragment>
                        )}
                    </Box>

                    <Box position="relative">
                        <Box
                            display="flex"
                            overflow="auto"
                            paddingLeft="m"
                            paddingRight={{ phone: 'm', desktop: '0' }}
                            paddingVertical="xxs" // space for outline
                            css={css`
                                /* enable momentum/inertia scrolling on iOS */
                                -webkit-overflow-scrolling: touch;
                                margin-left: -${(p) => p.theme.space.m}px;

                                ${hiddenScrollbar}
                            `}
                            ref={scrollContainerRef}
                        >
                            <Fragment>
                                {advertSummaries?.slice(0, 4).map((child, key) => {
                                    return (
                                        <Box
                                            key={key}
                                            marginLeft="s"
                                            marginRight="s"
                                            minWidth={itemWidth}
                                            width={itemWidth}
                                            css={css`
                                                &:first-of-type {
                                                    margin-left: 0;
                                                }

                                                &:last-of-type {
                                                    margin-right: 0;
                                                }

                                                ${adFocusHoverStyles(false, 0, hoverStyle)}
                                            `}
                                        >
                                            {child}
                                        </Box>
                                    )
                                })}
                            </Fragment>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                paddingHorizontal="m"
                                minWidth={itemWidth}
                                marginLeft="s"
                                aspectRatio="4/5"
                                height="fit-content"
                                css={css`
                                    background-color: ${buttonBackgroundColor};
                                `}
                            >
                                <Fragment>
                                    <Button
                                        onClick={onClickButton}
                                        testId="ad-detail-similar-ads-bap-ad-slider-button"
                                        width="100%"
                                        css={css`
                                            font-size: ${(p) => p.theme.fontSizes.s};
                                            font-weight: ${(p) => p.theme.fontWeights.bold};
                                        `}
                                    >
                                        Ich will mehr
                                    </Button>
                                </Fragment>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            <Divider />
        </Box>
    )
}
