import React, { FunctionComponent, PropsWithChildren } from 'react'
import { TestProps } from '@wh-components/core/common'
import { SpaceProps } from '@wh-components/system/space'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { ButtonProps } from '@wh-components/core/Button/Button'
import ShoppingCart from '@wh-components/icons/ShoppingCart'
import { printButtonTextColorFirefoxWorkaroundCss } from '@wh/common/chapter/components/Printing/printButtonTextColorFirefoxWorkaroundCss'
import { LinkWithButtonStyle } from '@wh-components/core/Button/LinkWithButtonStyle'
import { LayoutProps } from '@wh-components/system/layout'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useIsAndroidBrowser } from '@wh/common/chapter/components/AndroidOnelinkBanner/useAndroidOnelinkBanner'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'

interface OrderNowButtonProps extends TestProps, SpaceProps, ButtonProps, LayoutProps, PropsWithChildren {
    url: string
    taggingData: TaggingData
    optimizelyTrackEvent?: string
    disabled?: boolean
    eventName?: TaggingActionEvent
    onClick: () => void
}

export const OrderNowButton: FunctionComponent<OrderNowButtonProps> = ({
    size = 'large',
    url,
    taggingData,
    optimizelyTrackEvent,
    children,
    eventName = 'addetail_order_now_click',
    onClick,
    ...props
}) => {
    const isAndroidBrowser = useIsAndroidBrowser()
    const trackEvent = useOptimizelyTrack({ isAndroid: isAndroidBrowser })

    return (
        <LinkWithButtonStyle
            href={url}
            size={size}
            Icon={ShoppingCart}
            display={{ print: 'none' }}
            {...props}
            target="_blank"
            css={printButtonTextColorFirefoxWorkaroundCss}
            onClick={() => {
                if (optimizelyTrackEvent) {
                    trackEvent(optimizelyTrackEvent)
                }
                onClick()
                return callActionEvent(eventName, taggingData)
            }}
        >
            {children}
        </LinkWithButtonStyle>
    )
}
