import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import React, { Fragment, FunctionComponent, useEffect } from 'react'
import { callPageView } from '@wh/common/chapter/lib/tagging/tagging'
import { SeoMetaTagsForSeoMetaData } from '@wh/common/chapter/components/SeoMetaTags/SeoMetaTags'
import { BapJsonLd } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/JsonLD/BapJsonLd'
import { GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from 'next'
import { LeaderboardAndSkyscraperLayout } from '@wh/common/chapter/components/Layouts/LeaderboardAndSkyscraperLayout'
import { AdIdNotFoundAlert } from '@bbx/search-journey/sub-domains/ad-detail/components/common/AdIdNotFoundAlert/AdIdNotFoundAlert'
import { BapAdDetailContainer } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/BapAdDetailContainer'
import { NextRequest, OptionalPageOptions } from '@wh/common/chapter/types/nextJS'
import { getSeoSearchTerms } from '@bbx/search-journey/common/api/searchApiClient'
import { BapAdDetailUserZoom } from '@bbx/common/components/UserZoom/UserZoom'
import { getSellerType } from '@bbx/common/lib/getSellerType'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { SeoSearchTerms } from '@bbx/search-journey/common/SeoSearchTerms'
import { StoryblokBoxType } from '@wh/common/cms/components/bloks/StoryblokBox'
import { ISbStoryData } from '@storyblok/react'
import { getStoryblokStory } from '@wh/common/cms/api/storyblokIadProxyClient'
import { isNumberString } from '@wh/common/chapter/lib/validation'
import { adDetailGetServerSideProps } from '../../gebrauchtwagen/d/[[...seopath]]'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { HereMapContext } from '@bbx/search-journey/common/components/Map/HereMapContext'
import { SustainabilityDataItem } from '@bbx/search-journey/common/SustainabilityDataItem'

type ErrorProps = {
    is404: true
}

type BapAdDetailProps = {
    advertDetails: AdvertDetails
    seoSearchTerms: SeoSearchTerms[]
    storyblokPrivateContactBoxBannerBox: StoryblokBoxType | null
    sustainabilityDataItem: SustainabilityDataItem | null
    hereApiKey: string
}

const BapAdDetail: NextPage<BapAdDetailProps | ErrorProps> & OptionalPageOptions = (props) => {
    useEffect(() => {
        if ('is404' in props) {
            return
        }
    }, [props])

    const [profileData] = useProfileData()

    if ('is404' in props) {
        return <AdIdNotFoundAlert />
    }

    const { advertDetails, seoSearchTerms, storyblokPrivateContactBoxBannerBox, sustainabilityDataItem, hereApiKey } = props

    return (
        <Fragment>
            <AdDetailTagger advertDetails={advertDetails} />

            <SeoMetaTagsForSeoMetaData
                seoMetaData={advertDetails.seoMetaData}
                ogSiteImageUrl={advertDetails.advertImageList.advertImage[0]?.referenceImageUrl}
                dontSetDefaultOgSiteImageDimensions={true}
            />
            <BapAdDetailUserZoom />

            <BapJsonLd advertDetails={advertDetails} />

            <HereMapContext.Provider value={{ apiKey: hereApiKey }}>
                <BapAdDetailContainer
                    advertDetails={advertDetails}
                    sellerType={getSellerType(advertDetails.attributes)}
                    searchTerms={seoSearchTerms}
                    profileData={isUserLoggedIn(profileData) ? profileData : undefined}
                    key={advertDetails.id}
                    storyblokPrivateContactBoxBannerBox={storyblokPrivateContactBoxBannerBox}
                    sustainabilityDataItem={sustainabilityDataItem ?? undefined}
                />
            </HereMapContext.Provider>
        </Fragment>
    )
}

const AdDetailTagger: FunctionComponent<{ advertDetails: AdvertDetails }> = ({ advertDetails }) => {
    const trackEvent = useOptimizelyTrack()

    useEffect(() => {
        callPageView('adview', { taggingData: advertDetails.taggingData })
        trackEvent('bap_addetail_viewed')
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [advertDetails, advertDetails.id])
    return null
}

const initialCmsDataCache = {
    data: null,
    timestamp: 0,
}
const cmsDataCache: {
    data: ISbStoryData<StoryblokBoxType> | null
    timestamp: number
} = initialCmsDataCache

const cacheTimeInMinutes = 5
const getCmsStory = async (req: NextRequest | undefined) => {
    if (new Date().getTime() - cmsDataCache.timestamp < 1000 * 60 * cacheTimeInMinutes) {
        return cmsDataCache.data
    }

    try {
        const story = await getStoryblokStory<StoryblokBoxType>('403748489', req)
        cmsDataCache.data = story?.story ?? null
        return story?.story ?? null
    } catch (e) {
        console.error(e)
        return null
    } finally {
        cmsDataCache.timestamp = new Date().getTime()
    }
}

export const bapAdDetailGetServerSideProps = async (
    context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<BapAdDetailProps | ErrorProps>> => {
    const sustainabilityInfoPerCategory: SustainabilityDataItem[] = (
        await import('@bbx/search-journey/common/lib/sustainabilityInfoPerCategory')
    ).sustainabilityInfoPerCategory

    const { req } = context

    const bapAdDetailStory = await getCmsStory(req)

    const adDetailServerSidePropsFunc = adDetailGetServerSideProps(verticalIdMap.BAP)

    const adDetailPromise = adDetailServerSidePropsFunc(context)
    const searchTermsPromise = getSeoSearchTerms(verticalIdMap.BAP, 30)

    const [adDetailServerSideProps, seoSearchTerms] = await Promise.all([adDetailPromise, searchTermsPromise])

    // If a redirect or notFound was received, just return it
    if (!('props' in adDetailServerSideProps)) {
        return adDetailServerSideProps
    }

    const props = await adDetailServerSideProps.props

    if (!('advertDetails' in props)) {
        return { props }
    }

    const categoryIds = getCategoryIdsFromBreadcrumbs(props.advertDetails)
    const sustainabilityDataItemResult = findSustainabilityData(categoryIds, sustainabilityInfoPerCategory)

    return {
        props: {
            ...props,
            seoSearchTerms: [seoSearchTerms],
            storyblokPrivateContactBoxBannerBox: bapAdDetailStory?.content ?? null,
            sustainabilityDataItem: sustainabilityDataItemResult,
        },
    }
}

const getCategoryIdsFromBreadcrumbs = (advertDetails: AdvertDetails) => {
    const breadcrumbs = advertDetails.breadcrumbs
    if (!breadcrumbs) {
        return []
    }
    return (
        breadcrumbs
            .filter((breadcrumb) => breadcrumb.seoUrl.includes('-'))
            .map((breadcrumb) => breadcrumb.seoUrl.split('-'))
            .map((breadcrumbItems) => breadcrumbItems[breadcrumbItems.length - 1])
            .filter((value) => isNumberString(value)) ?? []
    )
}

const findSustainabilityData = (
    adCategoryIds: string[],
    sustainabilityInfoPerCategory: SustainabilityDataItem[],
): SustainabilityDataItem | null => {
    for (let i = adCategoryIds.length - 1; i >= 0; i--) {
        const item = sustainabilityInfoPerCategory.find((elem: SustainabilityDataItem) => `${elem.categoryId}` === adCategoryIds[i])
        if (item) {
            return item
        }
    }
    return null
}

export const getServerSideProps = bapAdDetailGetServerSideProps
BapAdDetail.Layout = LeaderboardAndSkyscraperLayout
BapAdDetail.allowAndroidBanner = false

export default BapAdDetail
