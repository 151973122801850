import React, { FunctionComponent, useMemo } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { LayoutProps } from '@wh-components/system/layout'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { getContactData } from '@bbx/search-journey/sub-domains/ad-detail/lib/getContactData'
import { Card } from '@wh-components/core/Card/Card'
import { Box } from '@wh-components/core/Box/Box'
import { Divider } from '@wh-components/core/Divider/Divider'
import { PriceBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PriceBox'
import { DealerProfile } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/DealerProfile'
import { AddressBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/AddressBox'
import { ContactButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/ContactButton'
import { PhoneNumberButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PhoneNumberButton'
import { KeyValue } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/KeyValuePair'
import { WebsiteLink } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/WebsiteLink'
import { SellerInfoButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/SellerInfoButton'
import { getContactTypeFromAdvertDetails } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/ContactType'
import { OrderNowButton } from '@bbx/lead-journey/lead-journey/components/OrderNowButton'
import { getBapAdvertPrice } from '@bbx/search-journey/common/lib/getBapAdvertPrice'
import { getOrganisationProfileWeblink } from '@bbx/search-journey/sub-domains/ad-detail/lib/getLinkToDealerProfile'

interface BapContactBoxDealerTopProps extends SpaceProps, LayoutProps {
    advertDetails: AdvertDetails
    onAdvertisingStateContacted: () => void
}

export const BapContactBoxDealerTop: FunctionComponent<BapContactBoxDealerTopProps> = ({
    advertDetails,
    onAdvertisingStateContacted,
    ...props
}) => {
    const { name, image, websiteUrl, address, phoneNumbers, contactName } = useMemo(() => getContactData(advertDetails), [advertDetails])
    const { mainPrice } = useMemo(
        () => getBapAdvertPrice(advertDetails.adTypeId, advertDetails.advertStatus, advertDetails.attributes),
        [advertDetails],
    )
    const contactTypeWithDetails = useMemo(() => getContactTypeFromAdvertDetails(advertDetails), [advertDetails])
    const organisationProfileWeblink = getOrganisationProfileWeblink(advertDetails.organisationDetails.organisationDetailLinkList)

    return (
        <Card padding={0} testId="ad-detail-contact-box-dealer-top" {...props}>
            <Box display="flex" flexDirection="column" gap="xs" padding="m">
                <PriceBox mainPrice={mainPrice} />
            </Box>
            <Box display={{ phone: 'none', tablet: 'block' }}>
                <Divider />
                <DealerProfile
                    name={name}
                    image={image}
                    link={websiteUrl}
                    adId={advertDetails.id}
                    taggingData={advertDetails.taggingData}
                    testIdPrefix="top"
                />
                <Divider />
                <Box display="flex" flexDirection="column" gap="s" padding="m">
                    {address && <AddressBox address={address} testIdPrefix="top" />}
                </Box>
                <Box display="flex" flexDirection="column" gap="s" padding="m" paddingTop={0}>
                    {contactTypeWithDetails.type !== 'EXTERNAL' ? (
                        <ContactButton
                            contactType={advertDetails.contactOption.contactType}
                            taggingData={advertDetails.taggingData}
                            adStatus={advertDetails.advertStatus}
                            orgId={advertDetails.organisationDetails?.id}
                            adId={advertDetails.id}
                            verticalId={advertDetails.verticalId}
                            doFacebookAndGoogleTracking={true}
                        >
                            Anbieter kontaktieren
                        </ContactButton>
                    ) : (
                        <OrderNowButton
                            url={contactTypeWithDetails.link}
                            taggingData={advertDetails.taggingData}
                            testId="contact-box-order-now-button"
                            onClick={onAdvertisingStateContacted}
                        >
                            {contactTypeWithDetails.buttonText}
                        </OrderNowButton>
                    )}
                    <PhoneNumberButton
                        phoneNumbers={phoneNumbers}
                        toggleWithContext={true}
                        adId={advertDetails.id}
                        orgId={advertDetails.organisationDetails?.id}
                        price={advertDetails.taggingData.tmsDataValues.tmsData.price}
                        taggingData={advertDetails.taggingData}
                        testIdPrefix="top"
                        doFacebookAndGoogleTracking={true}
                        verticalId={advertDetails.verticalId}
                    />
                    {organisationProfileWeblink && (
                        <SellerInfoButton
                            link={organisationProfileWeblink}
                            clickTaggingActionEvent="addetail_more_ads_click"
                            taggingData={advertDetails.taggingData}
                            testIdPrefix="top"
                        >
                            Weitere Anzeigen von diesem Shop
                        </SellerInfoButton>
                    )}
                </Box>
                <Box display="flex" flexDirection="column" padding="m" paddingTop={0}>
                    <KeyValue title="Kontakt" testIdPrefix="contact-box-dealer-top" content={contactName} direction="row" />
                    <KeyValue
                        title="Infos"
                        testIdPrefix="contact-box-dealer-top"
                        content={
                            websiteUrl && (
                                <WebsiteLink
                                    url={websiteUrl}
                                    adId={advertDetails.id}
                                    taggingData={advertDetails.taggingData}
                                    testIdPrefix="top"
                                />
                            )
                        }
                        direction="row"
                    />
                </Box>
            </Box>
        </Card>
    )
}
