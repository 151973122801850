import React, { FunctionComponent } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import sustainability_tree from '@bbx/static_hashed/img/sustainability_tree/sustainability_tree.svg'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { getSellerType, SellerType } from '@bbx/common/lib/getSellerType'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import ArrowRightIcon from '@wh-components/icons/ArrowRight'
import { css } from 'styled-components'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { palette } from '@wh-components/core/theme/palette'
import { SustainabilityDataItem } from '@bbx/search-journey/common/SustainabilityDataItem'

interface BapSustainabilityInfoProps {
    advertDetails: AdvertDetails
    sustainabilityDataItem?: SustainabilityDataItem
}

export const BapSustainabilityInfo: FunctionComponent<BapSustainabilityInfoProps & SpaceProps> = ({
    advertDetails,
    sustainabilityDataItem,
}) => {
    const message = getMessage(advertDetails, sustainabilityDataItem)
    if (!message) {
        return null
    }

    return (
        <ServerRoutingAnchorLink
            testId="sustainability-info-box"
            href="/will/nachhaltigkeit"
            type="anchor"
            onClick={() => {
                callActionEvent('addetail_sustainiability_box_info_link_click', undefined, {
                    category: getTaggingId(sustainabilityDataItem),
                })
            }}
            css={css`
                &:hover {
                    text-decoration: none;
                }
            `}
        >
            <Box
                display="flex"
                flexDirection="row"
                backgroundColor="#ECFBF8"
                borderRadius="m"
                paddingVertical="s"
                paddingHorizontal="m"
                marginHorizontal={{ phone: 'm', tablet: 0 }}
            >
                <Box display="flex" marginRight="s">
                    <ResponsiveImage src={sustainability_tree} width={80} height={80} alignSelf="center" aria-hidden={true} />
                </Box>
                <Box>
                    <Box>
                        <Text testId="sustainability-info-box-title" fontWeight="bold" color="#4DB963">
                            Dein Second Hand Effekt
                        </Text>
                    </Box>
                    <Text testId="sustainability-info-box-message" color={palette.verydarkgrey}>
                        {message}
                    </Text>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <ArrowRightIcon
                        size="xsmall"
                        aria-hidden={true}
                        marginLeft="s"
                        css={css`
                            color: ${(p) => p.theme.colors.palette.elephant};
                        `}
                    />
                </Box>
            </Box>
        </ServerRoutingAnchorLink>
    )
}

const messageTemplate =
    'Der Kauf gebrauchter {Kategorie} in dieser Kategorie vermeidet potentiell {#co2e} {unit} CO₂e im Jahr. Das ist mehr, als {#baeume} Bäume speichern können!'

const getMessage = (advertDetails: AdvertDetails, sustainabilityDataItem?: SustainabilityDataItem) => {
    const visible = isVisible(advertDetails, sustainabilityDataItem)
    if (!visible) {
        return undefined
    }

    return sustainabilityDataItem ? createMessage(sustainabilityDataItem) : undefined
}

const createMessage = (sustainabilityData: SustainabilityDataItem) => {
    return messageTemplate
        .replace('{Kategorie}', sustainabilityData.categoryName)
        .replace('{#co2e}', sustainabilityData.co2e)
        .replace('{unit}', sustainabilityData.unit)
        .replace('{#baeume}', sustainabilityData.trees)
}

const isVisible = (advertDetails: AdvertDetails, sustainabilityDataItem?: SustainabilityDataItem) => {
    if (!sustainabilityDataItem) {
        return false
    }
    const condition = advertDetails.attributeInformation?.filter((attribute) => 'Zustand' === attribute.treeAttributeElement.label)
    const isNew = condition?.[0]?.values.some((value) => 'Neu' === value.label)

    const isPrivate = getSellerType(advertDetails.attributes) === SellerType.PRIVATE

    return !isNew && isPrivate
}

const getTaggingId = (sustainabilityDataItem?: SustainabilityDataItem): string | undefined => {
    return sustainabilityDataItem?.categoryName ? sanitize(sustainabilityDataItem.categoryName) : undefined
}

const sanitize = (value: string): string => {
    let sanitizedResult = value.trim()
    sanitizedResult = sanitizedResult.replace(' / ', '_').replace('&', '_').replace('/', '_').replace(' ', '')
    sanitizedResult = sanitizeUmlauts(sanitizedResult)
    return sanitizedResult
}

const sanitizeUmlauts = (value: string): string => {
    return value
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ß/g, 'ss')
        .replace(/Ä/g, 'Ae')
        .replace(/Ö/g, 'Oe')
        .replace(/Ü/g, 'Ue')
}
