import { Button, ButtonProps, ButtonVariantType } from '@wh-components/core/Button/Button'
import { TestProps } from '@wh-components/core/common'
import { LayoutProps } from '@wh-components/system/layout'
import { SpaceProps } from '@wh-components/system/space'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { FunctionComponent, PropsWithChildren, useState } from 'react'
import PayliveryBuyNowIcon from '@wh-components/icons/PayliveryBuynow'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { toast, dismissAllToasts } from '@wh-components/core/Toast/Toast'
import { ApiErrorAlert } from '@wh/common/chapter/components/Alerts/ApiErrorAlert'
import { ErrorAlert } from '@wh/common/chapter/components/Alerts/ErrorAlert'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { redirectToLoginPage } from '@wh/common/chapter/lib/routing/bbxRouter'
import { setSmsAllowanceStatusAccepted, needsToAcceptSmsAllowance } from '@wh/common/sms-allowance/api/smsAllowanceApiClient'
import { redirectToChat } from '../../lib/conversationUtils'
import { SmsAllowanceWarningAlert } from '../AdContactForm/common/SmsAllowance'
import { SmsAllowanceModal } from '@wh/common/sms-allowance/components/SmsAllowanceModal'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'

interface BuyNowButtonProps extends Required<TestProps>, SpaceProps, ButtonProps, LayoutProps, PropsWithChildren {
    eventName?: TaggingActionEvent
    taggingData?: TaggingData
    disabled?: boolean
    variant?: ButtonVariantType
    adId: string
    onClick: () => void
}

export const BuyNowButton: FunctionComponent<BuyNowButtonProps> = ({
    eventName,
    taggingData,
    size = 'large',
    testId,
    onClick,
    ...props
}) => {
    const [isSmsAllowanceModalOpen, setIsSmsAllowanceModalOpen] = useState<boolean>(false)
    const [profileData] = useProfileData()

    const handleSmsAllowanceAccepted = async () => {
        if (!isUserLoggedIn(profileData)) {
            return
        }

        setIsSmsAllowanceModalOpen(false)

        try {
            const response = await setSmsAllowanceStatusAccepted(profileData.emailAddress)

            if (!response.confirmed) {
                toast(<ErrorAlert title="Fehler" message="Unbekannter Fehler" />, { type: 'error' })
                return
            }
        } catch (error) {
            toast(<ApiErrorAlert error={wrapInApiErrorIfNecessary(error).errorResponse} />, { type: 'error' })
            return
        }

        redirectToChat(props.adId, 'BUY_NOW')
    }
    const handleSmsAllowanceDeclined = () => {
        setIsSmsAllowanceModalOpen(false)
        toast(
            <SmsAllowanceWarningAlert
                onRetrySendMessage={() => {
                    dismissAllToasts()
                    setIsSmsAllowanceModalOpen(true)
                }}
            />,
            { type: 'warning', autoClose: false },
        )
    }

    return (
        <React.Fragment>
            <Button
                size={size}
                aria-label="Sofort kaufen"
                type="submit"
                Icon={PayliveryBuyNowIcon}
                display={{ print: 'none' }}
                testId={testId}
                {...props}
                onClick={async (e) => {
                    e.preventDefault()

                    onClick()

                    if (eventName) {
                        callActionEvent(eventName, taggingData)
                    }

                    if (!isUserLoggedIn(profileData)) {
                        redirectToLoginPage()
                        return
                    }

                    if (await needsToAcceptSmsAllowance(props.adId, profileData.emailAddress)) {
                        setIsSmsAllowanceModalOpen(true)
                        return
                    }
                    redirectToChat(props.adId, 'BUY_NOW')
                }}
            >
                Sofort kaufen
            </Button>
            <SmsAllowanceModal
                isOpen={isSmsAllowanceModalOpen}
                smsAllowanceType="ContactAdvertiser"
                onSmsAllowanceAccepted={handleSmsAllowanceAccepted}
                onSmsAllowanceDeclined={handleSmsAllowanceDeclined}
            />
        </React.Fragment>
    )
}
