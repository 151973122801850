import React, { Fragment, FunctionComponent, PropsWithChildren, useState } from 'react'
import { TestProps } from '@wh-components/core/common'
import { SpaceProps } from '@wh-components/system/space'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { Button, ButtonProps } from '@wh-components/core/Button/Button'
import PayliveryMakeofferIcon from '@wh-components/icons/PayliveryMakeoffer'
import { printButtonTextColorFirefoxWorkaroundCss } from '@wh/common/chapter/components/Printing/printButtonTextColorFirefoxWorkaroundCss'
import { SmsAllowanceModal } from '@wh/common/sms-allowance/components/SmsAllowanceModal'
import { SmsAllowanceWarningAlert } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/SmsAllowance'
import { dismissAllToasts, toast } from '@wh-components/core/Toast/Toast'
import { needsToAcceptSmsAllowance, setSmsAllowanceStatusAccepted } from '@wh/common/sms-allowance/api/smsAllowanceApiClient'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { ApiErrorAlert } from '@wh/common/chapter/components/Alerts/ApiErrorAlert'
import { ErrorAlert } from '@wh/common/chapter/components/Alerts/ErrorAlert'
import { redirectToLoginPage } from '@wh/common/chapter/lib/routing/bbxRouter'
import { LayoutProps } from '@wh-components/system/layout'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { useIsAndroidBrowser } from '@wh/common/chapter/components/AndroidOnelinkBanner/useAndroidOnelinkBanner'
import { redirectToChat } from '../lib/conversationUtils'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'

interface OfferButtonProps extends TestProps, SpaceProps, ButtonProps, LayoutProps, PropsWithChildren {
    eventName?: TaggingActionEvent
    taggingData: TaggingData
    optimizelyTrackEvent?: string
    disabled?: boolean
    adId: string
    onClick: () => void
}

export const OfferButton: FunctionComponent<OfferButtonProps> = ({
    size = 'large',
    adId,
    eventName,
    taggingData,
    optimizelyTrackEvent,
    testId,
    children = 'Anfrage senden',
    onClick,
    ...props
}) => {
    const [isSmsAllowanceModalOpen, setIsSmsAllowanceModalOpen] = useState<boolean>(false)
    const [profileData] = useProfileData()
    const isAndroidBrowser = useIsAndroidBrowser()
    const trackEvent = useOptimizelyTrack({ isAndroid: isAndroidBrowser })

    const handleSmsAllowanceAccepted = async () => {
        if (!isUserLoggedIn(profileData)) {
            return
        }

        setIsSmsAllowanceModalOpen(false)

        try {
            const response = await setSmsAllowanceStatusAccepted(profileData.emailAddress)

            if (!response.confirmed) {
                toast(<ErrorAlert title="Fehler" message="Unbekannter Fehler" />, { type: 'error' })
                return
            }
        } catch (error) {
            toast(<ApiErrorAlert error={wrapInApiErrorIfNecessary(error).errorResponse} />, { type: 'error' })
            return
        }

        redirectToChat(adId, 'MAKE_OFFER')
    }
    const handleSmsAllowanceDeclined = () => {
        setIsSmsAllowanceModalOpen(false)
        toast(
            <SmsAllowanceWarningAlert
                onRetrySendMessage={() => {
                    dismissAllToasts()
                    setIsSmsAllowanceModalOpen(true)
                }}
            />,
            { type: 'warning', autoClose: false },
        )
    }

    return (
        <Fragment>
            <Button
                size={size}
                type="submit"
                Icon={PayliveryMakeofferIcon}
                display={{ print: 'none' }}
                testId={testId}
                {...props}
                css={printButtonTextColorFirefoxWorkaroundCss}
                onClick={async (e) => {
                    e.preventDefault()

                    onClick()

                    // these are 2 different optimizely events for different experiments - the first one is tracked for all offer buttons, the second one only for the bottom sticky one - therefore we don't use the same event
                    trackEvent('bap_lead')
                    if (optimizelyTrackEvent) {
                        trackEvent(optimizelyTrackEvent)
                    }

                    if (eventName) {
                        callActionEvent(eventName, taggingData)
                    }

                    if (!isUserLoggedIn(profileData)) {
                        redirectToLoginPage()
                        return
                    }

                    if (await needsToAcceptSmsAllowance(adId, profileData.emailAddress)) {
                        setIsSmsAllowanceModalOpen(true)
                        return
                    }
                    redirectToChat(adId, 'MAKE_OFFER')
                }}
            >
                {children}
            </Button>
            <SmsAllowanceModal
                isOpen={isSmsAllowanceModalOpen}
                smsAllowanceType="ContactAdvertiser"
                onSmsAllowanceAccepted={handleSmsAllowanceAccepted}
                onSmsAllowanceDeclined={handleSmsAllowanceDeclined}
            />
        </Fragment>
    )
}
