import React, { Fragment, FunctionComponent, RefObject, useMemo } from 'react'
import { css } from 'styled-components'
import { AdvertContactDetails } from '@bbx/common/types/ad-detail/AdvertContactDetails'
import { AdvertDetails, ContactOption } from '@bbx/common/types/ad-detail/AdvertDetails'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { AdDetailStickyFooter } from '@bbx/search-journey/sub-domains/ad-detail/components/common/StickyFooter/AdDetailStickyFooter'
import { OrderNowButton } from '@bbx/lead-journey/lead-journey/components/OrderNowButton'
import { getContactTypeFromAdvertDetails } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/ContactType'
import { StickyFooterPhoneNumberButton } from '../../../common/StickyFooter/StickyFooterPhoneNumberButton'

interface CallAndOrderNowAdDetailStickyFooterProps {
    stickyFooterVisibilityTrackingRef: RefObject<HTMLDivElement>
    advertContactDetails: AdvertContactDetails
    advertDetails: AdvertDetails
    taggingData: TaggingData
    contactOption: ContactOption
    onAdvertisingStateContacted: () => void
}

export const CallAndOrderNowAdDetailStickyFooter: FunctionComponent<CallAndOrderNowAdDetailStickyFooterProps> = ({
    stickyFooterVisibilityTrackingRef,
    advertContactDetails,
    advertDetails,
    taggingData,
    onAdvertisingStateContacted,
}) => {
    const contactTypeWithDetails = useMemo(() => getContactTypeFromAdvertDetails(advertDetails), [advertDetails])

    return (
        <AdDetailStickyFooter
            stickyFooterVisibilityTrackingRef={stickyFooterVisibilityTrackingRef}
            buttons={
                <Fragment>
                    <StickyFooterPhoneNumberButton
                        adId={advertDetails.id}
                        taggingData={taggingData}
                        optimizelyTrackEvent="bbx_bottom_sticky_button_call_small"
                        advertContactDetails={advertContactDetails}
                        onAdvertisingStateContacted={onAdvertisingStateContacted}
                    />
                    {contactTypeWithDetails.type === 'EXTERNAL' && (
                        <OrderNowButton
                            url={contactTypeWithDetails.link}
                            size="large"
                            taggingData={taggingData}
                            optimizelyTrackEvent="bbx_bottom_sticky_button_order_now_small"
                            testId="ad-detail-footer-sticky-order-now-button"
                            css={css`
                                flex: 1 1 56%;
                            `}
                            onClick={onAdvertisingStateContacted}
                        >
                            Jetzt bestellen
                        </OrderNowButton>
                    )}
                </Fragment>
            }
        />
    )
}
