import { SellerType } from '@bbx/common/lib/getSellerType'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { PayliveryBadge } from '@wh/common/chapter/components/PayliveryBadge/PayliveryBadge'
import { isBuyNowAd, isPayliveryAd } from '@bbx/common/lib/isPayliveryAd'
import { getContactTypeFromAdvertDetails } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/ContactType'
import { ContactButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/ContactButton'
import { OfferButton } from '@bbx/lead-journey/lead-journey/components/OfferButton'
import { AdDetailSaveAdButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/SaveAdButton/AdDetailSaveAdButton'
import { AdDetailStickyHeader } from '@bbx/search-journey/sub-domains/ad-detail/components/common/StickyHeader/AdDetailStickyHeader'
import { OrderNowButton } from '@bbx/lead-journey/lead-journey/components/OrderNowButton'
import { Text } from '@wh-components/core/Text/Text'
import { Box } from '@wh-components/core/Box/Box'
import { theme } from '@wh-components/core/theme'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { Fragment, FunctionComponent, RefObject, useMemo } from 'react'
import { IconButton } from '@wh-components/core/Button/Button'
import ShareIcon from '@wh-components/icons/Share'
import { ShareButton } from '@wh/common/chapter/components/ShareButton'
import { PrintButton } from '@wh/common/chapter/components/PrintButton'
import { css } from 'styled-components'
import { BuyNowButton } from '@bbx/lead-journey/lead-journey/components/BuyNowButton/BuyNowButton'

interface BapAdDetailStickyHeaderProps {
    advertDetails: AdvertDetails
    sellerType: SellerType
    stickyHeaderVisibilityTrackingRef: RefObject<HTMLDivElement>
    price: string | undefined
    priceDescription: string | undefined
    onAdvertisingStateContacted: () => void
}

export const BapAdDetailStickyHeader: FunctionComponent<BapAdDetailStickyHeaderProps> = ({
    advertDetails,
    sellerType,
    stickyHeaderVisibilityTrackingRef,
    price,
    priceDescription,
    onAdvertisingStateContacted,
}) => {
    return (
        <AdDetailStickyHeader
            teaserAttributes={<BapStickyHeaderPriceBox advertDetails={advertDetails} priceDescription={priceDescription} price={price} />}
            headingText={advertDetails.description}
            contactBoxes={
                <BapStickyHeaderContactBoxes
                    advertDetails={advertDetails}
                    sellerType={sellerType}
                    adId={advertDetails.id}
                    adTitle={advertDetails.description}
                    taggingData={advertDetails.taggingData}
                    onAdvertisingStateContacted={onAdvertisingStateContacted}
                />
            }
            stickyHeaderVisibilityTrackingRef={stickyHeaderVisibilityTrackingRef}
        />
    )
}

const BapStickyHeaderPriceBox: FunctionComponent<{
    advertDetails: AdvertDetails
    priceDescription: string | undefined
    price: string | undefined
}> = (props) => {
    if (!props.price) {
        return null
    }

    return (
        <Box
            display="flex"
            alignItems="baseline"
            marginTop={`-${theme.space.m}px`}
            css={css`
                /* fix  pricebox due to negative marginTop which overlaps contact buttons */
                pointer-events: none;
            `}
        >
            <Text fontSize="s" color="palette.elephant" testId="sticky-header-price-description">
                {props.priceDescription}
            </Text>
            <Text fontSize="xl" fontWeight="bold" color="palette.primary.main" marginHorizontal="s" testId="sticky-header-price">
                {props.price}
            </Text>
            {isPayliveryAd(props.advertDetails) && (
                <Box alignSelf="center">
                    <PayliveryBadge id="sticky-header" />
                </Box>
            )}
        </Box>
    )
}

const BapStickyHeaderContactBoxes: FunctionComponent<{
    advertDetails: AdvertDetails
    sellerType: SellerType
    adId: string
    adTitle: string
    taggingData: TaggingData
    onAdvertisingStateContacted: () => void
}> = ({ adId, adTitle, advertDetails, taggingData, onAdvertisingStateContacted }) => {
    const contactTypeWithDetails = useMemo(() => getContactTypeFromAdvertDetails(advertDetails), [advertDetails])
    const isBuyNow = isBuyNowAd(advertDetails)

    return (
        <Box gap="s" display="flex">
            <AdDetailSaveAdButton
                adId={adId}
                adTitle={adTitle}
                taggingData={taggingData}
                testId="saveAdButton-header-sticky"
                variant="icon"
                eventNameSaveAdClick="addetail_sticky_header_favorite_ad_click_save"
                eventNameUnsaveAdClick="addetail_sticky_header_favorite_ad_click_unsave"
            />
            <ShareButton
                bodyText={advertDetails.description}
                url={advertDetails.seoMetaData.canonicalUrl}
                adId={advertDetails.id}
                taggingData={advertDetails.taggingData}
                shareEmailEvent="addetail_sticky_header_share_email_click"
                shareWhatsAppEvent="addetail_sticky_header_share_whatsapp_click"
                shareFacebookEvent="addetail_sticky_header_share_facebook_click"
                shareTwitterEvent="addetail_sticky_header_share_twitter_click"
                shareLinkEvent="addetail_sticky_header_share_link_click"
                shareLinkedInEvent="addetail_share_linkedin_click"
            >
                {(toggle) => (
                    <IconButton variant="outline" Icon={ShareIcon} name="ShareButton" aria-label="Teilen" onClick={toggle} size="small" />
                )}
            </ShareButton>

            <PrintButton
                eventName="addetail_sticky_print_click"
                testId="printButtonBapStickyHeader"
                id="printButtonBapStickyHeader"
                taggingData={advertDetails.taggingData}
            />

            {isPayliveryAd(advertDetails) ? (
                <Fragment>
                    <ContactButton
                        size="small"
                        type="ICON"
                        contactType={advertDetails.contactOption.contactType}
                        taggingData={advertDetails.taggingData}
                        adStatus={advertDetails.advertStatus}
                        variant="outline"
                        eventName="addetail_sticky_header_contact_seller_click"
                        testId="ad-detail-header-sticky-message-button"
                        optimizelyTrackEvent="bbx_top_sticky_button_contact_tablet"
                        doFacebookAndGoogleTracking={true}
                        orgId={advertDetails.organisationDetails?.id}
                        adId={advertDetails.id}
                        verticalId={advertDetails.verticalId}
                    />
                    <OfferButton
                        size="small"
                        adId={advertDetails.id}
                        eventName="addetail_sticky_offer_click"
                        taggingData={advertDetails.taggingData}
                        variant={isBuyNow ? 'outline' : 'solid'}
                        testId="ad-detail-header-sticky-offer-button"
                        onClick={onAdvertisingStateContacted}
                    />
                    {isBuyNow && (
                        <BuyNowButton
                            eventName="addetail_sticky_buynow_click"
                            taggingData={advertDetails.taggingData}
                            size="small"
                            adId={advertDetails.id}
                            testId="ad-detail-header-buynow-button"
                            onClick={onAdvertisingStateContacted}
                        />
                    )}
                </Fragment>
            ) : contactTypeWithDetails.type !== 'EXTERNAL' ? (
                <ContactButton
                    size="small"
                    contactType={advertDetails.contactOption.contactType}
                    taggingData={advertDetails.taggingData}
                    adStatus={advertDetails.advertStatus}
                    optimizelyTrackEvent="bbx_top_sticky_button_contact_tablet"
                    eventName="addetail_sticky_header_contact_seller_click"
                    testId="ad-detail-header-sticky-message-button"
                    doFacebookAndGoogleTracking={true}
                    orgId={advertDetails.organisationDetails?.id}
                    adId={advertDetails.id}
                    verticalId={advertDetails.verticalId}
                />
            ) : (
                <OrderNowButton
                    size="small"
                    url={contactTypeWithDetails.link}
                    taggingData={advertDetails.taggingData}
                    eventName="addetail_sticky_header_order_now_click"
                    testId="ad-detail-header-sticky-order-now-button"
                    onClick={onAdvertisingStateContacted}
                >
                    {contactTypeWithDetails.buttonText}
                </OrderNowButton>
            )}
        </Box>
    )
}
